import * as Y from "yjs";
import P2PCF from "p2pcf";

import { EventEmitter } from "events";

import * as encoding from "lib0/encoding";
import * as decoding from "lib0/decoding";

import * as syncProtocol from "y-protocols/sync";
import * as awarenessProtocol from 'y-protocols/awareness';

const TYPE_SYNC = 0;
const TYPE_AWARENESS = 1;


function f(clientId, roomId) {
  return `[${clientId} ${roomId}]`
}

function random(start, end) {
  return Math.round(Math.random() * (end-start)) + start;
}


export class P2pcfProvider {
  constructor(
    clientId,
    roomId,
    doc,
    {
      signaling = undefined,
      color,
      onconnect,
      peerOpts = {},
    } = {}
  ) {
    this.clientId = clientId;
    this.roomId = roomId;
    this.doc = doc;
    this.awareness = new awarenessProtocol.Awareness(doc);
    this.awareness.setLocalStateField("user", {
      clientId,
      color: color.color,
      colorLight: color.light,
    });
    this.peerOpts = peerOpts;

    this.p2pcf = new P2PCF(clientId, roomId, { workerUrl: signaling });

    this.p2pcf.on("peerconnect", (peer) => {
      const syncEncoder = encoding.createEncoder();
      encoding.writeVarUint(syncEncoder, TYPE_SYNC);
      syncProtocol.writeSyncStep1(syncEncoder, this.doc);
      this.p2pcf.send(peer, encoding.toUint8Array(syncEncoder));

      const awarenessEncoder = encoding.createEncoder();
      const awarenessUpdate = awarenessProtocol.encodeAwarenessUpdate(this.awareness, [this.doc.clientID]);
      encoding.writeVarUint(awarenessEncoder, TYPE_AWARENESS);
      encoding.writeVarUint8Array(awarenessEncoder, awarenessUpdate);
      this.p2pcf.send(peer, encoding.toUint8Array(awarenessEncoder));

      if (onconnect) {
        onconnect();
      }
    });

    this.p2pcf.on("msg", (peer, data) => {
      const decoder = decoding.createDecoder(new Uint8Array(data));

      const messageType = decoding.readVarUint(decoder);
      if (messageType === TYPE_SYNC) {
        const encoder = encoding.createEncoder();
        encoding.writeVarUint(encoder, TYPE_SYNC);
        const syncStep = syncProtocol.readSyncMessage(decoder, encoder, this.doc);
        if (syncStep === syncProtocol.messageYjsSyncStep1) {
          this.p2pcf.send(peer, encoding.toUint8Array(encoder));
        }
      } else if (messageType === TYPE_AWARENESS) {
        const update = decoding.readVarUint8Array(decoder);
        awarenessProtocol.applyAwarenessUpdate(this.awareness, update);
      } else {
        console.error(`${f(this.clientId, this.roomId)} Received invalid message`);
      }
    });

    this.doc.on("update", (update, origin) => {
      const encoder = encoding.createEncoder();
      encoding.writeVarUint(encoder, TYPE_SYNC);
      syncProtocol.writeUpdate(encoder, update);
      this.p2pcf.broadcast(encoding.toUint8Array(encoder));
    });

    this.awareness.on("update", ({ added, updated, removed }, origin) => {
      const changedClients = added.concat(updated).concat(removed);
      const awarenessUpdate = awarenessProtocol.encodeAwarenessUpdate(this.awareness, changedClients);
      const encoder = encoding.createEncoder();
      encoding.writeVarUint(encoder, TYPE_AWARENESS);
      encoding.writeVarUint8Array(encoder, awarenessUpdate);
      this.p2pcf.broadcast(encoding.toUint8Array(encoder));
    });

    this.p2pcf.start();
  }
};