import * as Y from "yjs";
import { yCollab, yUndoManagerKeymap } from "y-codemirror.next";
// import { WebrtcProvider } from "y-webrtc";
import { P2pcfProvider } from "./y-p2pcf";

import { basicSetup } from "codemirror";
import { EditorState } from "@codemirror/state";
import { EditorView, keymap } from "@codemirror/view";
import { insertTab, indentLess } from "@codemirror/commands";
import { python } from "@codemirror/lang-python";

import { Terminal } from "xterm";

import * as random from "lib0/random";

export const userColors = [
  { color: '#30bced', light: '#30bced33' },
  { color: '#6eeb83', light: '#6eeb8333' },
  { color: '#ffbc42', light: '#ffbc4233' },
  { color: '#ecd444', light: '#ecd44433' },
  { color: '#ee6352', light: '#ee635233' },
  { color: '#9ac2c9', light: '#9ac2c933' },
  { color: '#8acb88', light: '#8acb8833' },
  { color: '#1be7ff', light: '#1be7ff33' },
];

export const userColor = userColors[random.uint32() % userColors.length];

const theme = EditorView.theme({
  "&": { height: "200px" },
  ".cm-scroller": { overflow: "auto" },
});

const tabKeymap = [
  {
    key: "Tab",
    preventDefault: true,
    run: insertTab,
  },
  {
    key: "Shift-Tab",
    preventDefault: true,
    run: indentLess,
  },
];


function init(room, name, { onconnect } = {}) {
  // TODO: consider server-client model where master will
  // be the server and serve all requests to run code
  function initPyodideWorker() {
    const pyodideWorker = new Worker(new URL("webworker.js", import.meta.url));
    pyodideWorker.onmessage = ((event) => {
      const { type, data } = event.data;
      if (type === "stdout") {
        yarray.push([data]);
      } else if (type === "done") {
        ymap.set("state", "stopped");
      } else if (type === "error") {
        yarray.push([data]);
      } else {
        console.log("Unknown message type:", event.data);
      }
    });

    return pyodideWorker;
  }
  let pyodideWorker = initPyodideWorker();


  const ydoc = new Y.Doc();
  const provider = new P2pcfProvider(
    name,
    room,
    ydoc,
    {
      color: userColor,
      onconnect,
    },
  );

  const ymap = ydoc.getMap("app");
  ymap.observe((event) => {
    event.changes.keys.forEach((change, key) => {
      if (change.action ==! "update") {
        return;
      }

      const value = ymap.get(key);
      if (value === change.oldValue) {
        return;
      }
      
      if (value === "running") {
        toggleButtonState("running");
      } else if (value === "stopped") {
        // TODO: ideally, we interrupt using shared buffer
        // theres an issue with cross origin stuff
        pyodideWorker.terminate();
        pyodideWorker = initPyodideWorker();
        toggleButtonState("stopped");
      }
    });
  });


  const ytext = ydoc.getText("codemirror");

  provider.awareness.setLocalStateField("user", {
    name,
    color: userColor.color,
    colorLight: userColor.light,
  });

  const state = EditorState.create({
    doc: ytext.toString(),
    extensions: [
      basicSetup,
      theme,
      EditorView.lineWrapping,
      python(),
      keymap.of([
        ...tabKeymap,
        ...yUndoManagerKeymap,
      ]),
      yCollab(ytext, provider.awareness),
    ],
  });

  const editor = new EditorView({ state, parent: document.getElementById("editor") });


  let terminalInitialized = false;
  const yarray = ydoc.getArray("terminal");
  yarray.observe((event) => {
    const terminalContents = yarray.toArray();
    if (terminalContents.length === 0) {
      terminal.reset();
      return;
    }
    if (terminalInitialized) {
      terminal.write(terminalContents[terminalContents.length-1]);
    } else {
      terminalContents.forEach((line) => {
        terminal.write(line);
      })
      terminalInitialized = true;
    }
  });

  const terminal = new Terminal({ convertEol: true });
  terminal.resize(80, 10);
  terminal.open(document.getElementById("terminal"));


  const runButton = document.getElementById("run");
  const stopButton = document.getElementById("stop");
  toggleButtonState(ymap.get("state") || "stopped");
  runButton.addEventListener("click", async () => {
    ymap.set("state", "running");
    yarray.delete(0, yarray.toArray().length);
    const text = editor.state.doc.toString();
    pyodideWorker.postMessage({ type: "run", data: text });
  });
  stopButton.addEventListener("click", async () => {
    ymap.set("state", "stopped");
  });
  function toggleButtonState(state) {
    if (state === "running") {
      runButton.disabled = true;
      stopButton.disabled = false;
    } else if (state === "stopped") {
      runButton.disabled = false;
      stopButton.disabled = true;
    } else {
      console.error("Unknown state: " + state);
    }
  }
}


const enterButton = document.getElementById("enter");
enterButton.addEventListener("click", () => {
  const roomInput = document.getElementById("room");
  const nameInput = document.getElementById("name");
  const app = document.getElementById("app");

  enterButton.disabled = true;
  createButton.disabled = true;
  roomInput.disabled = true;
  nameInput.disabled = true;

  init(
    roomInput.value || "default",
    nameInput.value || "Anon" + Math.floor(Math.random() * 101).toString(),
    { onconnect: () => { app.hidden = false; } },
  );
});

const createButton = document.getElementById("create");
createButton.addEventListener("click", () => {
  const roomInput = document.getElementById("room");
  const nameInput = document.getElementById("name");
  const app = document.getElementById("app");

  enterButton.disabled = true;
  createButton.disabled = true;
  roomInput.disabled = true;
  nameInput.disabled = true;
  app.hidden = false;

  init(
    roomInput.value || "default",
    nameInput.value || "Anon" + Math.floor(Math.random() * 101).toString(),
  );
});
